.form {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
}

.card {
    width: 100%;
    height: auto;
    background-color: #fff;
    overflow: hidden !important;
    border-radius: 4px
}

.d-none {
    display: none
}

.header_form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.header_form>h4{
    text-align: center;
    color: #3E1B7C;
    font-weight: 600;
}
.header_form>p{
    text-align: center;
}
.header_form span {
    height: 30px;
    width: 30px;
    background-color: red;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #fff;
    position: relative
}

.header_form span::after {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -10px;
    background-color: red;
    transform: rotate(45deg)
}

.footer_form {
    border-top: 1px solid #ccc;
    padding: 15px;
    display: flex;
    gap: 10px
}

.footer_form button {
    width: 100%;
    height: 40px;
    cursor: pointer;
    border: none;
    background-image: linear-gradient(to right, #431B8F , #B696F6);
    font-size: 15px;
    color: #fff;
    border-radius: 3px
}

.input_field {
    position: relative;
    width: 100%;
    margin-top: 20px
}

.input_field input {
    height: 40px;
    width: 100%;
    padding: 0px 9px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 3px;
    transition: all 0.5s
}

.input_field span {
    position: absolute;
    top: 10px;
    left: 7px;
    font-size: 15px;
    pointer-events: none;
    transition: all 0.5s;
    background-color: #fff;
    padding: 0px 7px
}

.input_field input:focus~span,
.input_field input:valid~span {
    top: -6px;
    font-size: 12px
}

.form_body {
    padding: 15px
}

.final {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px
}

.final_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.final_content span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px
}

.final_content p {
    text-align: center
}

.final .check {
   
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff
}