/* Custom CSS to style the header cells */
.rdt_Table, .rdt_TableRow, .rdt_TableCol,
.rdt_TableCol_Sortable,
.rdt_TableHeader,
.rdt_TableFooter,
.rdt_TableHead {
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow content */
    text-overflow:clip;
    padding: 0;
    margin: 0;
    font-size: 13px!important;
  }
  .rdt_TableHead{
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Show ellipsis for overflow content */
    font-size: 14px!important;
    padding: 0;
    margin: 0
  }
.tableSrCell{
  width: 10px;
  text-align: center;
  padding: 0;
  margin: 0;
}